





















import { Component, Mixins } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import SmoobuConfigForm from './smoobu-config-form.vue';
import SmoobuConfigDto from '../dto/smoobu-config.dto';
import smoobu from '../services/smoobu.service';

@Component({
  components: {
    SmoobuConfigForm,
  },
})
export default class SmoobuConfigCard extends Mixins(HandlesErrorMixin) {
  private loading = false;
  private syncing = false;

  private smoobuConfig: SmoobuConfigDto = {
    apiKey: '',
    channelId: '',
    blockedChannelId: '',
  };

  async getSmoobuConfig() {
    this.loading = true;

    try {
      const response = await smoobu.getSmoobuConfig();
      this.smoobuConfig = {
        ...this.smoobuConfig,
        ...response.data,
      };
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async updateSmoobuConfig(dto: SmoobuConfigDto) {
    this.loading = true;

    try {
      await smoobu.updateSmoobuConfig(dto);
      this.smoobuConfig = dto;

      this.$notify.success(this.$t('success.smoobuConfigUpdated').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async syncSmoobu() {
    this.syncing = true;

    try {
      await smoobu.syncSmoobu();

      this.$notify.success(this.$t('success.smoobuSynchronized').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.syncing = false;
    }
  }

  mounted() {
    this.getSmoobuConfig();
  }
}
