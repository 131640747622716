








import { Component, Vue } from 'vue-property-decorator';
import SmoobuConfigCard from '../components/smoobu-config-card.vue'

@Component({
  metaInfo(this: ChannelManager) {
    return { title: this.$t('pageTitle.channelManager').toString() };
  },
  components: {
    SmoobuConfigCard,
  },
})
export default class ChannelManager extends Vue {}
