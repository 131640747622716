import api from '@/app/core/services/api.service';
import SmoobuConfigDto from '../dto/smoobu-config.dto';
import CheckSmoobuApiKeyDto from '../dto/check-smoobu-api-key.dto';

const checkApiKey = (apiKey: string) => (
  api.get<CheckSmoobuApiKeyDto>(`/host/smoobu/check-api-key/${apiKey}`)
);

const getSmoobuConfig = () => api.get<SmoobuConfigDto>('/host/smoobu');

const updateSmoobuConfig = (dto: SmoobuConfigDto) => api.put('/host/smoobu', dto);

const syncSmoobu = () => api.post('/host/smoobu/sync');

export default {
  syncSmoobu,
  checkApiKey,
  getSmoobuConfig,
  updateSmoobuConfig,
};
