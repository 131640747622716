







































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import env from '@/env';
import { gt, required } from '@/app/core/validation';
import FormMixin from '@/app/core/mixins/form.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import SmoobuConfigDto from '../dto/smoobu-config.dto';
import smoobuService from '../services/smoobu.service';

@Component
export default class SmoobuConfigForm extends Mixins(FormMixin, HandlesErrorMixin) {
  @Prop({ type: Boolean, default: false }) syncing!: boolean;

  @Prop({ type: Object, required: true })
  private value!: SmoobuConfigDto;

  protected data: SmoobuConfigDto = {
    apiKey: '',
    channelId: '',
    blockedChannelId: '',
  };

  protected rules = {
    apiKey: [required],
    channelId: [required, gt(0)],
    blockedChanelId: [required, gt(0)],
  };

  private apiKeyExists = true;

  private checkingApiKeyExistence = false;

  private apiKeyExistenceChecked = false;

  @Emit('sync')
  sync() { return true; }

  get webhookUrl() {
    return `${env.API_BASE_URL}/smoobu/webhook/${this.value.apiKey}`;
  }

  get apiKeyExistsRule() {
    return this.apiKeyExists || this.$t('validation.apiKeyExists');
  }

  get apiKeyValid() {
    return this.rules.apiKey.every((rule) => rule(this.data.apiKey) === true);
  }

  @Watch('value')
  setData() {
    this.data = { ...this.value };
    if (this.data.apiKey) {
      this.apiKeyExists = true;
      this.apiKeyExistenceChecked = true;
    }
  }

  copyWebhookUrlToClipboard() {
    navigator.clipboard.writeText(this.webhookUrl);
    this.$notify.success(this.$t('success.webhookURLCopied').toString());
  }

  onApiKeyChanged() {
    this.apiKeyExistenceChecked = false;
    this.apiKeyExists = this.apiKeyValid;
  }

  async checkApiKeyExistence() {
    if (!this.apiKeyValid) return;
    this.checkingApiKeyExistence = true;

    try {
      const response = await smoobuService.checkApiKey(this.data.apiKey);
      this.apiKeyExists = response.data.success;
      this.apiKeyExistenceChecked = true;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.checkingApiKeyExistence = false;
    }
  }

  created() {
    this.setData();
  }
}
